import {
  Alert,
  Button,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
} from 'antd';
import {FC, useState} from 'react';
import {useTranslation} from '../../translation';
import {DEFAULT_API_NAME, useApi, useUserIsLGAdmin} from '../../util/Auth';
import {OwnerRecord} from '../table/Owner';

/** @WARN: this button is for the "swap email" procedure, **not** for "reset password"
 * or classic "email change". This procedure updates user email (in DB + Cognito + WordPress),
 * then resets the user password, then sends a generic email "here are your temp credentials" to new email.
 */
export const OwnerSwapEmailButton: FC<{
  owner: OwnerRecord;
  onFinish?: () => void;
}> = (props) => {
  const {owner, onFinish} = props;
  const isLGAdmin = useUserIsLGAdmin();
  const api = useApi();
  const {t} = useTranslation();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  if (!isLGAdmin) {
    return <div />;
  }

  return (
    <div>
      <Modal
        centered
        forceRender
        open={open}
        okButtonProps={{style: {display: 'none'}}}
        cancelButtonProps={{style: {display: 'none'}}}
        closable={true}
        onCancel={() => setOpen(false)}
        title={t('users.changeEmailAndResetPassword')}
        width={600}
      >
        <Alert
          message={t('users.changeEmailAndResetPassword.info')}
          type="warning"
          style={{marginBottom: 12}}
        />
        <Form
          form={form}
          initialValues={{previousEmail: owner.user.email}}
          onFinish={async (values) => {
            if (loading) {
              return;
            }
            if (!api) {
              return;
            }
            if (!values.email) {
              message.error('Email address should not be blank');
              return;
            }
            if (values.previousEmail === values.email) {
              message.error('Email address is the same');
              return;
            }

            setLoading(true);
            try {
              await api.post(
                DEFAULT_API_NAME,
                `/user/swapEmail/${owner.userId}`,
                {body: {email: values.email}},
              );
            } catch (err) {
              const msg = `An error has occurred`;
              message.error(msg);
              console.log(msg, err);
              setLoading(false);
              return;
            }

            setLoading(false);
            message.success('OK');
            setOpen(false);
            onFinish?.();
          }}
        >
          <Form.Item
            name="previousEmail"
            label={t('users.previousEmail')}
            labelCol={{span: 6}}
          >
            <Input type="email" disabled />
          </Form.Item>

          <Form.Item
            name="email"
            label={t('register.email')}
            labelCol={{span: 6}}
            rules={[{required: true}]}
          >
            <Input type="email" />
          </Form.Item>

          <Row justify="end">
            <Popconfirm
              title={t('users.changeEmailAndResetPassword')}
              okText={t('general.yes')}
              cancelText={t('general.no')}
              onConfirm={() => form.submit()}
            >
              <Button type="primary" danger={true} loading={loading}>
                {t('general.submit')}
              </Button>
            </Popconfirm>
          </Row>
        </Form>
      </Modal>

      <Button type="primary" danger={true} onClick={() => setOpen(true)}>
        {t('users.changeEmailAndResetPassword')}...
      </Button>
    </div>
  );
};
